import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Background from '../../common/images/side-bg.jpg';
import loginImg from "../../common/images/login-logo.png"
class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'nav-item has-sub active'
            }else{
            status = 'nav-item has-sub enable';
        }
        return status;
    }
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
  <div className="navbar-header expanded">
    <ul className="nav navbar-nav flex-row position-relative">
      <li className="nav-item mr-auto">
        <a className="navbar-brand" href="#">
        {/* <img src={loginImg} /> */}
          <h3 className="brand-text">Admin</h3>
        </a>
      </li>
      <li className="nav-item d-md-none"><a href="javascript:void(0)" className="nav-link close-navbar mb-side-nav"><i className="ft-x"></i></a></li>
    </ul>
  </div>
  <div className="navigation-background" style={{background: `url(${Background})`}}></div>
  <div className="main-menu-content ps ps--active-y" style={{height: "587px;"}}>
  <Scrollbars className="custom-scroll-wrap">
    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <li className={this.activeMenu('dashboard')}>
        <a href="/dashboard">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Dashboard</span>
        </a>
      </li>
      <li className={this.activeMenu('packages')}>
					<a title="Packages" href="/packages">
						<i className="ft-tab-management" aria-hidden="true"></i>
						<span>Packages</span>
					</a>
				</li>
        <li className={this.activeMenu('formbuilder')}>
					<a title="Form Builder" href="/formbuilder">
						<i className="ft-formbuilder" aria-hidden="true"></i>
						<span>Form Builders</span>
					</a>
				</li>
        <li className={this.activeMenu('subscriptions')}>
					<a title="Subscription" href="/subscriptions">
						<i className="ft-subscription" aria-hidden="true"></i>
						<span>Subscriptions</span>
					</a>
				
				</li>
        <li className={this.activeMenu('user')}>
        <a title="User" href="/user">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Users</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('companylist')}>
					<a title="Company" href="/company">
						<i className="ft-company" aria-hidden="true"></i>
						<span>Companies</span>
					</a>
		        </li>:''} 
      
      

      <li className={this.activeMenu('dashboardmanagement')}>
					<a title="Dashboard Management Builder" href="/dashboardmanagement">
						<i className="ft-server"></i>
						<span>Dashboard Management</span>
					</a> 
				</li>
        <li className={this.activeMenu('advertisementcategory')}>
					<a title="Dashboard Management Builder" href="/advertisementcategory">
						<i className="ft-server"></i>
						<span>Advertisement Categories</span>
					</a>
				</li>
        <li className={this.activeMenu('advertisement')}>
        <a title="Advertisement" href="/advertisement">
          <i className="ft-faq"></i>
          <span className="menu-title" data-i18n="">Advertisement</span>
        </a>
      </li>
        <li className={this.activeMenu('Aifeed')}>
					<a href="/aifeed">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Manage AI Feed</span>
					</a>
				</li>
        <li className={this.activeMenu('Category')}>
					<a title="Category" href="/category">
						<i className="ft-category" aria-hidden="true"></i>
						<span>Categories</span>
					</a>
				</li>
      
      <li className={this.activeMenu('product')}>
        <a href="/product">
          <i className="ft-server" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Products</span>
        </a>
      </li>
      <li className={this.activeMenu('storelist')}>
        <a href="/store">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Stores</span>
        </a>
      </li>
      <li className={this.activeMenu('coupons')}>
					<a title="Coupons" href="/coupons">
						<i className="ft-coupon" aria-hidden="true"></i>
						<span>Coupons</span>
					</a>
				</li>
        <li className={this.activeMenu('product-outstock')}>
					<a title="product-outstock" href="/product-outstock">
						<i className="ft-outstock" aria-hidden="true"></i>
						<span>Out Of Stock</span>
					</a>
				</li>
      <li className={this.activeMenu('payment-history')}>
        <a href="/payment-history">
          <i className="ft-server" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Order History</span>
        </a>
      </li>
      {/* <li className={this.activeMenu('payment-completed-history')}>
					<a title="Payment History" href="/payment-completed-history">
						<i className="history-icon icon" aria-hidden="true"></i>
						<span>Completed Orders  </span>
					</a>
				</li>  */}
     <li className={this.activeMenu('form-entries')}>
					<a title="Form Entries" href="/formentries">
						<i className="ft-formentries" aria-hidden="true"></i>
						<span>Form Entries</span>
					</a>
				</li> 

        <li className={this.activeMenu('plugincontent')}>
        <a title="Plugin Content" href="/plugincontent">
						<i className="ft-plugincontent" aria-hidden="true"></i>
						<span>Plugin Content</span>
					</a>
      </li>
      <li className={this.activeMenu('product-gallery')}>
					<a title="Settings" href="/product-gallery">
						<i className="ft-productgallery" aria-hidden="true"></i>
						<span>Product Gallery</span>
					</a>
				</li>
      <li className={this.activeMenu('Color')}>
        <a href="/Color">
          <i className="ft-gallery"></i>
          <span className="menu-title" data-i18n="">Colors</span>
        </a>
      </li>

      <li className={this.activeMenu('fonts')}>
        <a href="/fonts">
          <i className="ft-type"></i>
          <span className="menu-title" data-i18n="">Fonts</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('templates')}>
        <a href="/templates">
          <i className="ft-feather"></i>
          <span className="menu-title" data-i18n="">Templates</span>
        </a>
        </li>:''} 
	    <li className={this.activeMenu('setting')}>
        <a href="/setting">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Settings</span>
        </a>
      </li>
      
    </ul>
    </Scrollbars>
  </div>
</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;