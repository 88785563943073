import React, { Component } from 'react';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl,productURL} from '../Config/Config';
import axios from 'axios';
import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import MyEditor from '../Membership/MyEditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Scrollbars } from 'react-custom-scrollbars';

import Addembedinput from './Addembedinput';
import moment from 'moment/moment';


import { useParams, useNavigate } from 'react-router-dom';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
  const discount = [
	{ value: 'flat', label: 'Flat' },
	{ value: 'percentage', label: 'Percentage' }
  ];

class Add extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
            domain_id:'',
            cat_name:'',
			product_name:'',
			short_name:'',			
			cat_priority:'',
			description:'',
			short_description:'',
			product_image:'',
			product_color:'',
			product_quantity:'',
			price:'',
			fileName: [],
			thumbDefault: '',
			thumbtypeimg: [],
			colornamelist:[],
			categorynamelist:[],
			subscriptionlist:[],	
			image: [],
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			Loading:false,
			recurring_checked:false,
			recurring:0,
			subscription_plan:'',
			subs_plan_final_all:'',
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No',
			free_shipping:0,
			free_shipping_checked:false,
			goods_type:'digital',
			imageSets:'',
			removeID:[
        	],
			selectedDimension:'',
			productdimensionvalue:'',
			dimensionlist:[],
			discount_price:'',
			discount_gprice:'',
			selectedgdisctype:{ value: 'flat', label: 'Flat' },
			selecteddisctype:{ value: 'flat', label: 'Flat' },
			discounttype:'flat',
			gdiscounttype: 'flat',
			startDate:'',
			endDate:'',
			storenamelist: [],
			unlimited_quantity: 'no',
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
        this.myCallback(); 

    }
	


  onChangeHandler=event=>{
   /* let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        productimage_name: file.name,
        product_image_preview: reader.result,
        image: file
      });
    };
    reader.readAsDataURL(file);*/
    this.setState({ image: event.target.files })
  }

  handleClickSelecetCategory = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
		var catvalue = event.target.options[selectedIndex].getAttribute('data-value');
		this.setState({
				cat_name: keys,	
		});

	}


	handleClickSelecetgoods = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');

	this.setState({
			goods_type: keys,	
	});

}


  onThumbChanged = (e) => {	
    this.setState({
      thumbDefault: e.currentTarget.value
      });
	  
  }
  

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      let subs_plan = [];
      const {name, value} = event.target;  
     
      this.setState({
          [name]: value
        });


      if(name === 'recurring'){
      	 this.setState({recurring_checked: !this.state.recurring_checked});
      }


      if(name === 'subscription_plan[]'){
      	 
      	  var ref = $("#"+event.target.id)[0].attributes["data-ref"].value;
      		console.log(event.target.value)
            subs_plan.push({subscription_plan_price: event.target.value,subscription_plan_id:event.target.id});
        				
			localStorage.setItem('subs_plan_'+ref, JSON.stringify(subs_plan));

      }

      if(name === 'free_shipping'){
      	 this.setState({free_shipping_checked: !this.state.free_shipping_checked});
      }

		
    }

	
	handleChangeDimension = selectedDimension => {

		this.setState({ selectedDimension});
		this.setState({ productdimensionvalue : selectedDimension.value });
 }


	componentDidMount() {
		this.storelistloadedData();
		document.title = PageTitle('Product Add');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}

      var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getcategorynamelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						categorynamelist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
					});
				
				}else{
				//console.log("test")
				}
			});
			axios.get(apiUrl+'product/getcolornamelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						colornamelist: [{value: '', label: 'Select Color'}].concat(res.data.colornamelist)
					});
				
				}else{
				//console.log("test")
				}
			});
			axios.get(apiUrl+'subscription/getsubscriptionlist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						subscriptionlist: res.data.subscriptionlist
					})

			  var subscriptionlist = res.data.subscriptionlist;
				subscriptionlist.forEach(function(data,index) {
				localStorage.removeItem('subs_plan_'+index);
				localStorage.removeItem('subs_plan_all');
				});
				}else{
				//console.log("test")
				}
			});

	
    }
	storelistloadedData(postobject=''){
		var admin_id = localStorage.getItem("admin_id");
		var accesscode = localStorage.getItem("company_code");  
	
	   axios.get(apiUrl+'product/getstorenamelist?admin_id='+admin_id+'&accesscode='+accesscode).then(res => {
		 if(res.data.status === 'success'){
			this.setState({  storenamelist: [{value: '', label: 'Select Store'}].concat(res.data.storelist)});
		 }else{
			  this.setState({  storelist: ''});
		 }
	   }); 
	  }
        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var free_shipping = '';
				if(this.state.free_shipping_checked === true){
					 free_shipping = '1';
				}else{
					 free_shipping = '0';
				}


				var recurring = '';
				if(this.state.recurring_checked === true){
					 recurring = '1';

					  let subs_plan_final = [];
			      /********To gather the plan price and sent to api**/
               var admin_id = localStorage.getItem('admin_id');
			   axios.get(apiUrl+'subscription/getsubscriptionlist?admin_id='+admin_id)
		    	.then(res => {
					if(res.data.status == 'success'){
						var subscriptionlist = res.data.subscriptionlist;
						subscriptionlist.forEach(function(data,index) {
						subs_plan_final.push(localStorage.getItem('subs_plan_'+index));
						localStorage.setItem('subs_plan_all',subs_plan_final);
						});
					}else{
					//console.log("test")
					}
		    	});

				}else{
					 recurring = '0';
				}



				
			/******/

			setTimeout(
				    function() {
				
				    if(recurring == '1'){
						var sub_final_all =	localStorage.getItem("subs_plan_all");
					}else if(recurring == '0'){
						var sub_final_all =	null;
					}

					var onetime = '';
					var price ='';
					if(this.state.price !== ''){
						 onetime = '1';
						 price = formPayload.price;

					}else{
						 onetime = '0';
						 price = null;
					}

					var galleryimg = 'No';
					if(Object.keys(formPayload.selectedimages).length >0){
	                   galleryimg = 'Yes';
					}

                     
					var dimension;
					if(formPayload.selectedDimension !== ''){
					   dimension =formPayload.selectedDimension.value;
					}else{
						dimension =formPayload.productdimensionvalue;
					}

					var discounttype;
					if(formPayload.selecteddisctype !== ''){
						discounttype =formPayload.selecteddisctype.value;
					}else{
						discounttype =formPayload.discounttype;
					}
					var gdiscounttype;
					if(formPayload.selectedgdisctype !== ''){
						gdiscounttype =formPayload.selectedgdisctype.value;
					}else{
						gdiscounttype =formPayload.gdiscounttype;
					}
					if(formPayload.startDate !== ''){
						var stday = new Date(formPayload.startDate);
					    var startday = moment(stday).format("YYYY-MM-DD HH:mm");
					}else{
						var startday = '';
					}

					if(formPayload.endDate !== ''){
						var enday = new Date(formPayload.endDate);
					    var endday = moment(enday).format("YYYY-MM-DD HH:mm");
					}else{
						var endday = '';
					}
					
					

					var postObject = {
						admin_id : localStorage.getItem("admin_id"),
						domain_id : formPayload.domain_id,
						product_name: formPayload.product_name,
						short_name:formPayload.short_name,
						product_priority: formPayload.product_priority,
						price: price,
						product_description: formPayload.description,
						short_description:formPayload.short_description,					
						product_image:formPayload.image,
						selected_category_id:formPayload.cat_name,
						product_color: formPayload.product_color,
						product_quantity: formPayload.product_quantity,
						status:  status,
						recurring: recurring,
						onetime: onetime,
						free_shipping: free_shipping,
						subs_plan_all: sub_final_all,
						goods_type:formPayload.goods_type,
						removeId: formPayload.removeID,
						product_dimension: dimension,
						discounttype: discounttype,
						gdiscounttype: gdiscounttype,
						discount_price: formPayload.discount_price,
						discount_gprice: formPayload.discount_gprice,
						start_date: startday,
						unlimited_quantity:this.state.unlimited_quantity,
						end_date: endday,
						store_id:formPayload.store_name,
					};

							let formData = new FormData();

							for(let k in postObject) {

							formData.append(k, postObject[k]);
							}

							var filesdata;
			                if(galleryimg == 'No'){
			                    filesdata = formPayload.image;	
			                }else{
			                	 filesdata = this.state.selectedimages;	
			                }  
						    for (var i in filesdata) {
				 		    	formData.append('images[]',filesdata[i])
							}   
							
							var image_sets = formPayload.imageSets;
							
							for(let i in image_sets){

							if(image_sets[i]['id'] ==  'undefined'){
								formData.append('imageSets['+ i + '][id]','null')		
							}else if(image_sets[i]['id'] !==  undefined && image_sets[i]['id'] !==  ''){
								formData.append('imageSets['+ i + '][id]',image_sets[i]['id'])
							}
							formData.append('imageSets['+ i + '][image_name]',image_sets[i]['image_name'])
							formData.getAll('imageSets');
							}

							
						    //this.props.getAddProduct(formData,config);

							axios.post(apiUrl+"product/add",formData,config).then(res => {
								if(res.data.status === "success"){
										$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
												setTimeout(
												function() {
													this.props.navigate('/product');
													// $('.success_message').html(this.props.history.push('/color'));
												}
												.bind(this),
												3000
												);
									}else{
										$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
										
										setTimeout(
										function() {
											$('.success_message').html('');
										}
										.bind(this),
										3000
										);
									}
								});
						}
						.bind(this),
						3000
					);
			}
	}

	validateForm() {
		const {product_name,short_name,cat_name,product_priority,price,subs_plan_final_all,product_quantity,product_color,description,short_description,image,status,recurring_checked, selectedDimension} = this.state;
		let errors = {};
      	let formIsValid = true;

      	if(!cat_name){
        	formIsValid = false;
        	$('.errorcategory_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcategory_name').html('');
		}
		if(recurring_checked == false){
			if(!price){
				formIsValid = false;
				$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
			}else{
				
				$('.errorprice').html('');
			}
		}
	
		if(!selectedDimension){
			formIsValid = false;
			$('.errorproduct_dimension').html('<span class="errorspan">Please select the dimension</span>');
		}else{
			
			$('.errorproduct_dimension').html('');
		}

		if (!product_name) {
			formIsValid = false;
			$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_name){
			$('.errorproduct_name').html('');
		}
     

		/*if (!short_name) {
			$('.errorshort_name').html('<span class="errorspan">Please select the field</span>');
		}else if(short_name){
			$('.errorshort_name').html('');
		}*/
		

		if (!product_priority) {
			formIsValid = false;
			$('.errorproduct_priority').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_priority){
			$('.errorproduct_priority').html('');
		}

		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}

		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}

		if (!image) {
			formIsValid = false;
			$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
		}else if(image){
			$('.errorimage').html('');
		}

		if (!product_color) {
			formIsValid = false;
			$('.errorproduct_color').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_color){
			$('.errorproduct_color').html('');
		}
		if(this.state.unlimited_quantity == 'no'){
			if (!product_quantity) {
				formIsValid = false;
				$('.errorproduct_quantity').html('<span class="errorspan">Please fill the field</span>');
			}else if(product_quantity){
				$('.errorproduct_quantity').html('');
			}
		}


		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.productadd !== this.props.productadd){
    		if(Object.keys(Props.productadd).length > 0){
				this.setState({Loading:false});

					if(Props.productadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.productadd[0].message+'</h3></div>');
					
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/product'));
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.productadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    		
    }

    getGalleryimages() {
    var imageArr = this.state.imagelist;
    if(imageArr!== undefined && imageArr!== null){
      if(Object.keys(imageArr).length > 0) {
         const imageDetails = imageArr.map((image, Index) => {
       		var checked = false;
            return (
                        <div className="asp-col" key={Index}>   
                        <input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
                        <label><img src={image['url']} alt="" className="" /></label>
                        </div>
                     );
    
     });
      return imageDetails;
     }
    } else {
      return (<div className="">No images found</div>);
    }
  }

   opengalleryImages = () => {
		axios.get(apiUrl+'product/getimagelist')
		.then(res => {
			if(res.data.status == 'success'){
				this.setState({imagelist: res.data.imagelist})
			}else{
			   
			}
		});
		this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
   }
   galleryClose = () => {

   		if(Object.keys(this.state.selectedimages).length > 0){
   			 this.setState({galleryimg: 'Yes'});
   		}
   	    this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
		$('.cargallery-popup').removeClass('active');
   }


   //list selected image  from gallery
	 getafteruploaded(){
	 	var imgArray = this.state.selectedimages;
	 	if(Object.keys(imgArray).length > 0) {
		 	var thumbDefaultArr = this.state.thumbDefault;
			 const imgThumblist = imgArray.map((images, index) => {
			 	console.log(images,'images')
			 			var indicheckFlag = false;
		 				if(thumbDefaultArr != null && thumbDefaultArr == index){
							indicheckFlag = true;
						} 
						  return (
							<li className="thumb" key={index}  >
								<input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} />
								<span><img src={productURL+'/'+images}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}

	 }
  //check gallery images
   Checkgallery = (indexs,value) =>{
      var imageArr = [...this.state.selectedimages];
      const index = imageArr.findIndex(images => images === indexs);
      if(index > -1) {
     	 imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
      } else {
      imageArr.push(value);
      }
      this.setState({selectedimages: imageArr});
  }
 
  myCallback = (imageSets,imageID) => {

    this.setState({imageSets: imageSets,removeID: imageID});
   };


	handleChangediscount = selecteddisctype => {

		this.setState({ selecteddisctype});
		this.setState({ discounttype : selecteddisctype.value });


   }
   
   handleChangegdiscount = selectedgdisctype => {

	this.setState({ selectedgdisctype});
	this.setState({ gdiscounttype : selectedgdisctype.value });


}


   setStartDate = picked => {		
	console.log(picked);
	this.setState({ startDate: picked });
  };
  setEndDate = picked => {
	this.setState({ endDate: picked });
  };

  handleClickSelecetColor = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');

	this.setState({
			product_color: keys,	
	});

}
handleClickSelecetStore = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');
	this.setState({
			store_name: keys,		
			cat_name: ''
	});
	this.categorylistdata(keys);
}
categorylistdata = (store_name) => {
	var admin_id = localStorage.getItem('admin_id');
	axios.get(apiUrl+'product/getcategoryfilternamelist?admin_id='+admin_id+'&store_id='+store_name)
	
	.then(res => {
		if(res.data.status == 'success'){
		
			this.setState({
				categorynamelist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
			});
		}else{
			
		}
	});
}
	handlequntityInputChange = (event) => {
		const { name, value, type, checked } = event.target;
		this.setState({
		[name]: type === 'radio' ? value : checked ? 'yes' : value,
		});
  };
  render() {
	const navigate = this.props.navigate;
	let preview = '';
	const {selectedOption,categorynamelist,storenamelist,colornamelist,subscriptionlist,selectedDimension, selecteddisctype, selectedgdisctype} = this.state;


	var current = this;
	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="product" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div className="content-wrapper-before"></div>
		<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
			<div className="title">
				<h4>Add Product</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.product_name} />
					<div className="errorproduct_name"></div>
				</div>

				<div className="form-group">
					<label>Category<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetCategory}  value={categorynamelist.cat_name}>                    
                    {this.state.categorynamelist.map((categorynamelist) => <option key={categorynamelist.value} data-key={categorynamelist.value} value={categorynamelist.value} data-value ={categorynamelist.label}>{categorynamelist.label}</option>)}
                    </select>
					<div className="errorcategory_name"></div>
				 </div>
	        	{/* <div className="form-group">	
					<label>Description:<span class="required" style={{ color: "red" }} > * </span> : </label>								
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div> */}
				<div className="form-group">					
						<label>Description:</label>
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.description}
							onReady={ editor => {console.log( 'Editor is ready to use!', editor );} }
							onChange={ ( event, editor ) => {
									const data = editor.getData();
									this.setState({description: data});
							} }
						/>
					 
						<div className="errorpackage_content"></div>
					</div>
					<div className="form-group">
						<label>Unlimited Quantity:</label>
							<div className="radio-options">
								<label className='unlimited-quantity'>
									<input type="radio" name="unlimited_quantity" value="yes" onChange={this.handlequntityInputChange} checked={this.state.unlimited_quantity === 'yes'} />
									Yes
								</label>
								<label className='unlimited-quantity'>
									<input  type="radio"   name="unlimited_quantity"  value="no"   onChange={this.handlequntityInputChange} checked={this.state.unlimited_quantity === 'no'}/>
									No
								</label>
							</div>
					</div>
					{this.state.unlimited_quantity === 'no' && (
							<div className="form-group">	
								<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
								<input type="text" name="product_quantity" onChange={this.handleInputChange} className="form-control" value={this.state.product_quantity} />
								<div className="errorproduct_quantity"></div>
							</div>
					)}
				

				
				 {(this.state.cat_name == 406 || this.state.cat_name == 407) &&  
						<div className="form-group">

						<label>Gift Discount Type: </label>
						<Select 
						value={selectedgdisctype?selectedgdisctype:{ value: '', label: 'Please Select'}}
						options={discount} 
						onChange={this.handleChangegdiscount}
						isSearchable={false}
						/>
						<label>Discount: </label>
						<input onChange={this.handleInputChange} type="text" name="discount_gprice" value={this.state.discount_gprice} />
						</div> }

				<div className="form-group">
			    <label>Product Image:</label>

			    <div className="choose-car" onClick={this.opengalleryImages.bind(this)}>
						 <span>Upload from gallery</span>
				</div>
				<p className="or"><b>OR</b></p>

				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file[]" multiple onChange={this.onChangeHandler} /></span>
                </div>
                <div className="errorimage"></div>
                </div>

				<div className="form-group">	
							<label>Sale Start Date: </label>
								<DatePicker
									name="startDate"
									selected={this.state.startDate}
									onChange={date => this.setStartDate(date)}
									showTimeSelect
									timeFormat="h:m a"
									timeIntervals={30}
									timeCaption="time"
									dateFormat="M-d-Y H:m"
								/>
				</div>
						
				
				 <div className="form-group">
					<label>Product Subscription: </label>


					<div className="form-group">	
							<label>Price $<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price} />
							<div className="errorprice"></div>
						</div>
			
						<div className="form-group">
								<input onChange={this.handleInputChange} type="checkbox"  name="recurring" checked={this.state.recurring_checked}/>
								<span>Recurring</span>
						</div>
           </div> 


		<div className="product-subscription">
          { ((this.state.recurring_checked === true))? 
            	 <>
            <div className="form-group">
					<label>Subscription Plan & Price<span class="required" style={{ color: "red" }} > * </span> : </label>
				   	
				 </div>

				<div className="product-subscription-form-row">
				 
                <div className="form-group">
					                    
                    {this.state.subscriptionlist.map((subscriptionlist,index) => 
                    	 <>
                    	 <span>{subscriptionlist.subscription_name+' $'} </span>
                    	<input onChange={this.handleInputChange} type="text" data-ref={index} id={subscriptionlist.subscription_id} name="subscription_plan[]" />
	                
	                </>)}
					<div className="errorsubscription_plan"></div>
				 </div></div>
				  </>
            : '' } </div>
				
				{this.state.enableuploadgalley ==  true && <div className="cargallery-popup active">
					<span className="close-gallery" onClick={this.galleryClose.bind(this)}>X</span>
					<div className="cargallery-popup-inner">
						<Scrollbars className="custom-scroll-wrap">
						 <div className="car-listing">
								{this.getGalleryimages()}
						</div>
						</Scrollbars>
					</div>						

				<div className="btn-group">	
					  <span className="btn" onClick={this.galleryClose.bind(this)} disabled={(this.state.Loading ===true)?true:false}> 
					  {this.state.Loading ===true &&  <span className="load-data"></span>}OK</span>
				</div>
				
				</div>}
			</div>
                  
			<div className="form-right">
			{/*<div className="form-group">
					<label>Short Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="short_name" onChange={this.handleInputChange} className="form-control" value={this.state.short_name} />
					<div className="errorshort_name"></div>
				</div>*/}
					<div className="form-group">
					<label>Stores<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetStore}  value={storenamelist.store_name}>                    
                    {this.state.storenamelist.map((storenamelist) => <option key={storenamelist.value} data-key={storenamelist.value} value={storenamelist.value} >{storenamelist.label}</option>)}
                    </select>
					<div className="errorstore_name"></div>
					</div>
					<div className="form-group">					
						<label>Short Description:</label>
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.short_description}
							onReady={ editor => {console.log( 'Editor is ready to use!', editor );} }
							onChange={ ( event, editor ) => {
								      const data = editor.getData();
									this.setState({short_description: data});
								} }
						/>
						<div className="errorpackage_content"></div>
					</div>

				<div className="form-group">	
					<label>Color<span class="required" style={{ color: "red" }} > * </span> : </label>

				    <select  name="product_color"  onChange={current.handleClickSelecetColor}  value={colornamelist.name}>                    
                    {this.state.colornamelist.map((colornamelist) => <option key={colornamelist.value} data-key={colornamelist.value} value={colornamelist.value} >{colornamelist.label} </option>)}
                    </select> 


				     {/* <input type="text" name="product_color" onChange={this.handleInputChange} className="form-control" value={this.state.product_color} />  */}
					<div className="errorproduct_color"></div>
				</div>




				<div className="form-group">	
					<label>Priority<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_priority" onChange={this.handleInputChange} className="form-control" value={this.state.product_priority} />
					<div className="errorproduct_priority"></div>
				</div>

				<div className="form-group">					
					<label>Product image dimension<span class="required" style={{ color: "red" }} > * </span> : </label>
						<Select 
							 options={lang.common.image_size_option}  
							value={selectedDimension?selectedDimension:{ value: '0', label: 'Select Dimension' }}
							onChange={this.handleChangeDimension}
							placeholder="Select Dimenesion" />
					<div className="errorproduct_dimension"></div>
				</div>	

               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div>
               

               <div className="form-group">
					<label>Product Type<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetgoods}  >                    
                    <option key='digital' data-key='digital' value={this.state.goods_type} >Digital</option>
                    <option key='nondigital' data-key='nondigital' value={this.state.goods_type} >Non Digital</option>
                    </select>
					{/* <div className="errorcategory_name"></div> */}
				 </div>

				 <div className="form-group">	
							<label>Sale End Date: </label>
								<DatePicker
									name="endDate"
									selected={this.state.endDate}
									onChange={date => this.setEndDate(date)}
									showTimeSelect
									timeFormat="h:m a"
									timeIntervals={30}
									timeCaption="time"
									dateFormat="M-d-Y H:m"
								/>
				</div>
	              <div className="form-group">

				    <label>Discount Type: </label>
				    <Select 
						   value={selecteddisctype?selecteddisctype:{ value: '', label: 'Please Select'}}
                            options={discount} 
                            onChange={this.handleChangediscount}
							isSearchable={false}
                    />
					<label>Discount: </label>
				    <input onChange={this.handleInputChange} type="text" name="discount_price" value={this.state.discount_price} />
                  </div> 

				   <div className="form-group">
				    <input onChange={this.handleInputChange} type="checkbox" name="free_shipping" checked={this.state.free_shipping_checked}/>
	                <span>Free Shipping</span>
                  </div> 
				
				 <div className='form-group'>
				     <h4>Image Embed URL </h4>
				    <div className="domain-url">
					    <Addembedinput myCallback={this.myCallback} imagename={this.state.imageSets}/>
					</div>
		     	 </div>

			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Add);